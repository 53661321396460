import { supabase } from '../supabase';


export interface OllamaMessage {
    role: string;
    content: string;
}

// Define the TypeScript type for the table
export interface LLMModel {
    id: number;
    created_at: string;
    name: string;
    description: string;
    rank: number;
    is_active: boolean;
    internal_name: string;
}

export const convertToOllamaMessages = (chatMessages: ChatMessage[]): OllamaMessage[] => {
    return chatMessages.map(({ message }) => ({
        role: message.role,
        content: message.content,
    }));
};


// Function to retrieve chat messages based on chat_session
export const supaBaseGetSessions = async () => {
    const { data: chatMessages, error } = await supabase
        .from('chat_session')
        .select('*')

    if (error) {
        console.error('Error fetching chat messages:', error);
        return null;
    }

    return chatMessages;
}


// Function to retrieve chat messages based on chat_session
export const supaBaseGetChatMessagesBySession = async (chatSessionId: string) => {
    const { data: chatMessages, error } = await supabase
        .from('chat_message')
        .select('*')
        .eq('chat_session', chatSessionId);

    if (error) {
        console.error('Error fetching chat messages:', error);
        return null;
    }

    return chatMessages;
}

export const supaBaseFetchModels = async () => {
    const { data, error } = await supabase
        .from('model')
        .select('*')
        .returns<LLMModel[]>()

    if (error) {
        console.error('Error fetching data:', error);
        return [];
    }

    return data ?? [];
}



export const supaBaseWriteToChatMessage = async (message: ChatMessage) => {
    //convert to snake case.
    const ddata = {
        id: message.id,
        message: message.message,
        chat_session: message.chatSession,
    }

    const { data: insertedData, error } = await supabase
        .from("chat_message")
        .insert([ddata]);

    if (error) {
        console.error('Error inserting data:', error);
    } else {
        console.log('Data inserted successfully:', insertedData);
    }
}

export const supaBaseWriteToChatSession = async (session: ChatSession) => {

    //convert to snake case.
    const ddata = {
        id: session.id,
        title: session.title,
        last_model_used: session.lastModelUsed,
    }

    const { data: insertedData, error } = await supabase
        .from("chat_session")
        .insert([ddata]);

    if (error) {
        console.error('Error inserting data:', error);
    } else {
        console.log('Data inserted successfully:', insertedData);
    }
}
