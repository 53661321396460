import React, { useRef, useState } from 'react';
import { Box, Textarea, Grid, ActionIcon, Paper, Center } from '@mantine/core';
import { convertToOllamaMessages } from '@/utility/Utilities';
import PaperSendIcon from '../Icons/PaperSendIcon';
import classes from './UserInput.module.css'


interface InputProps {
    handleSend: (message: string) => Promise<void>
}

const ChatBox: React.FC<InputProps> = ({ handleSend }) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [message, setMessage] = useState<string>('');

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {

        setMessage(e.target.value);
        if (textareaRef.current) {
            // Reset height to auto to calculate new scroll height
            textareaRef.current.style.height = 'auto';

            // Set the height to the scrollHeight or limit to 6 lines (~96px)
            const scrollHeight = textareaRef.current.scrollHeight;
            const maxHeight = 6 * 24; // Assuming 24px line-height for 6 lines
            textareaRef.current.style.height = `${Math.min(scrollHeight, maxHeight)}px`;
            if (scrollHeight > 132){
                textareaRef.current.style.overflowY = 'auto';
            } else {
                textareaRef.current.style.overflowY = 'hidden';
            }
        }
    };


    return (
        <div style={{ flex: '0 0 97px' }}>
            <Box style={{ maxWidth: 768, margin: 'auto', padding: 16 }}>
                <Grid align='center'>
                    <Grid.Col span="auto" style={{ position: 'relative' }}>
                        <textarea
                            ref={textareaRef}
                            className={classes.userchat}

                            rows={1} // Start with a single row
                            // style={{ overflowY: 'auto' }} // Enable vertical scroll if necessary

                            placeholder="Message Function..."
                            // variant={'filled'}
                            value={message}
                            onChange={handleInputChange}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter' && !event.shiftKey) {
                                    event.preventDefault(); // Prevents adding a new line
                                    handleSend(message);
                                    setMessage('');
                                }
                            }}
                        // radius={'xl'}
                        // autosize
                        // minRows={1}
                        // maxRows={1}

                        />
                        <Paper
                            style={{
                                position: 'absolute',
                                right: '30px',
                                top: '50%',
                                transform: 'translateY(-50%)', // Vertically center
                                width: '40px',
                                height: '40px',
                            }}
                            variant="filled"
                            bg={'white'}
                            radius="xl"


                        >
                            <Center>
                                <PaperSendIcon data-disabled={message?.length <= 0 ? 'true' : 'false'} onClick={() => { handleSend(message); setMessage(''); }} />
                            </Center>
                        </Paper>
                    </Grid.Col>
                    {/* <Grid.Col span="content">

                    </Grid.Col> */}
                </Grid>
            </Box>
        </div>
    );
};

export default ChatBox;