import { Grid, Avatar, Skeleton } from "@mantine/core";
import ModelSelect from "./ModelSelect";


import { md5 } from 'js-md5';
import { useEffect, useState } from "react";
import { User } from "@supabase/supabase-js";
import { supabase } from '../../supabase';


function getGravatarUrl(email: string): string {
    const hash = md5(email.trim().toLowerCase());
    return `https://www.gravatar.com/avatar/${hash}?d=404`;
}

async function imageExists(url: string): Promise<boolean> {
    try {
        const response = await fetch(url);
        return response.ok;
    } catch {
        return false;
    }
}

function getInitials(email: string): string {
    const [name] = email.split('@');

    return name.charAt(0).toUpperCase();
}

async function getUserImage(email: string): Promise<{ val: string, isUrl: boolean }> {
    const gravatarUrl = getGravatarUrl(email);

    if (await imageExists(gravatarUrl)) {
        return { val: gravatarUrl, isUrl: true };
    } else {
        return { val: getInitials(email), isUrl: false };
    }
}

export function ChatHeader() {


    const [avatar, setAvatar] = useState<{ val: string, isUrl: boolean }>({ val: "??", isUrl: false })
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        const fetchUser = () => {
            supabase.auth.getUser()
                .then(({ data: { user }, error }) => {
                    if (error) {
                        console.error('Error fetching user:', error);
                    } else {
                        setUser(user);
                    }
                })
                .catch((err) => {
                    console.error('Unexpected error:', err);
                });
        };

        fetchUser();
    }, []);

    useEffect(() => {

        if (!user || !user.email) {
            return
        }

        loadModels();

        async function loadModels() {
            const userData = await getUserImage(user!.email as string);

            setAvatar(userData)
        }
    }, [user])


    return <>
        <Grid>
            <Grid.Col span="content">
                <ModelSelect />

            </Grid.Col>
            <Grid.Col span="auto"></Grid.Col>
            <Grid.Col span="content">
                {avatar.val === "??" ? <>
                    <Skeleton height={"38px"} circle mb="xl" /></> : <>

                    {avatar.isUrl ? <>
                        <Avatar src={avatar.val} alt="it's me" />


                    </> : <>
                        <Avatar color="cyan" radius="xl">{avatar.val}</Avatar>
                    </>}

                </>}
            </Grid.Col>
        </Grid>
    </>
}

