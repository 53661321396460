import { Divider, Skeleton, NavLink, ScrollArea, Text } from '@mantine/core';
import { isToday, isYesterday, isThisWeek, isThisMonth, isThisYear } from 'date-fns';
import classes from './NavBarSessions.module.css'; // Import your CSS module

interface InputProps {
    sessions: ChatSession[];
    sessionsLoading: boolean;
    loadChat: (chatSession: ChatSession) => void;
}

const NavBarSessions: React.FC<InputProps> = ({ sessions, sessionsLoading, loadChat }) => {
    // Create a shallow copy of the sessions array to modify as we filter
    let tempSessions = [...sessions];

    const filterAndRemove = (condition: (session: ChatSession) => boolean): ChatSession[] => {
        const filteredSessions = tempSessions.filter(condition);
        // Remove filtered sessions from tempSessions
        tempSessions = tempSessions.filter(session => !condition(session));
        return filteredSessions;
    };

    const todaySessions = filterAndRemove(session => isToday(new Date(session.createdAt ?? "")));
    const yesterdaySessions = filterAndRemove(session => isYesterday(new Date(session.createdAt ?? "")));
    const thisWeekSessions = filterAndRemove(session => isThisWeek(new Date(session.createdAt ?? "")));
    const thisMonthSessions = filterAndRemove(session => isThisMonth(new Date(session.createdAt ?? "")));
    const thisYearSessions = filterAndRemove(session => isThisYear(new Date(session.createdAt ?? "")));

    return (
        <>
            <ScrollArea scrollbarSize={4} mb={24}>
                {sessionsLoading ? (
                    <>
                        <Divider my="xs" label="Today" labelPosition="center" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Divider my="xs" label="Yesterday" labelPosition="center" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Divider my="xs" label="This Week" labelPosition="center" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Divider my="xs" label="This Month" labelPosition="center" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Divider my="xs" label="This Year" labelPosition="center" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Skeleton height={8} mt={6} radius="xl" />
                    </>
                ) : (
                    <>
                        {todaySessions?.length > 0 && (
                            <>
                                <Text size='12px' fw={600}>Today</Text>
                                {todaySessions
                                    .sort((a, b) => {
                                        const dateA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
                                        const dateB = b.createdAt ? new Date(b.createdAt).getTime() : 0;

                                        // Sort sessions with missing createdAt to the end
                                        return dateB - dateA;
                                    }).map(session => (
                                        <NavLink
                                            className={classes.navLink}
                                            c={'fnsidebarlinkgray'}
                                            key={session.id}
                                            label={session.title ? (session.title.length > 30 ? `${session.title.slice(0, 30)}...` : session.title) : "Missing title"}
                                            onClick={() => { loadChat(session) }}
                                        />
                                    ))}
                            </>
                        )}
                        {yesterdaySessions?.length > 0 && (
                            <>
                                <Text size='12px' fw={600}>Yesterday</Text>
                                {yesterdaySessions.map(session => (
                                    <NavLink
                                        className={classes.navLink}
                                        c={'fnsidebarlinkgray'}

                                        key={session.id}
                                        label={session.title ? (session.title.length > 30 ? `${session.title.slice(0, 30)}...` : session.title) : "Missing title"}
                                        onClick={() => { loadChat(session) }}

                                    />
                                ))}
                            </>
                        )}
                        {thisWeekSessions?.length > 0 && (
                            <>
                                <Text size='12px' fw={600}>This Week</Text>
                                {thisWeekSessions.map(session => (
                                    <NavLink
                                        className={classes.navLink}
                                        c={'fnsidebarlinkgray'}

                                        key={session.id}
                                        label={session.title ? (session.title.length > 30 ? `${session.title.slice(0, 30)}...` : session.title) : "Missing title"}
                                        onClick={() => { loadChat(session) }}

                                    />
                                ))}
                            </>
                        )}
                        {thisMonthSessions?.length > 0 && (
                            <>
                                <Text size='12px' fw={600}>This Month</Text>
                                {thisMonthSessions.map(session => (
                                    <NavLink
                                        className={classes.navLink}
                                        c={'fnsidebarlinkgray'}
                                        key={session.id}
                                        label={session.title ? (session.title.length > 30 ? `${session.title.slice(0, 30)}...` : session.title) : "Missing title"}
                                        onClick={() => { loadChat(session) }}

                                    />
                                ))}
                            </>
                        )}
                        {thisYearSessions?.length > 0 && (
                            <>
                                <Text size='12px' fw={600}>This Year</Text>
                                {thisYearSessions
                                    .sort((a, b) => {
                                        const dateA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
                                        const dateB = b.createdAt ? new Date(b.createdAt).getTime() : 0;

                                        // Sort sessions with missing createdAt to the end
                                        return dateB - dateA;
                                    }).map(session => (
                                        <NavLink
                                            className={classes.navLink}
                                            c={'fnsidebarlinkgray'}
                                            key={session.id}
                                            label={session.title ? (session.title.length > 30 ? `${session.title.slice(0, 30)}...` : session.title) : "Missing title"}
                                            onClick={() => { loadChat(session) }}

                                        />
                                    ))}
                            </>
                        )}
                    </>
                )}
            </ScrollArea>
        </>
    );
}

export default NavBarSessions;