import { Group, Button, Paper, Box, Grid, SimpleGrid } from '@mantine/core';
import { Text } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { supabase } from '../../supabase';
import logo from '../../assets/logonewchat.png';

import classes from './ChatContent.module.css';
import { User } from '@supabase/supabase-js';
import { UserService } from '@/utility/UserService';

interface InputProps {
    handleSend: (message: string) => Promise<void>

}


const EmptyChat: React.FC<InputProps> = (props) => {

    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        const userService = UserService.getInstance();
    
        userService.getUser().then((user) => {
            if (user) {
                setUser(user);
            }
        });
    }, []);


    const today = new Date();
    const yearAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());


    const { } = props;

    console.log(user)

    return (
        <Box className={classes.scrollContainer}>
            <Box pt={"20vh"}>
                <img
                    style={{
                        width: 45,
                        height: 21,
                        marginLeft: "-5px",
                        marginBottom: "10px",
                        objectFit: 'contain',
                    }}
                    src={logo}
                    alt="Logo"
                />
            </Box>
            <Text fw='600' pb={"10px"} size='48px'>Hi{user?.user_metadata.display_name ? " " : ""}{user?.user_metadata.display_name}, how can I help you?</Text>
            <SimpleGrid cols={4} spacing="sm" verticalSpacing="sm"  >
                <Box className={classes.newbox} onClick={() => props.handleSend('How can I program Hello World in python?')} style={{
                    cursor: 'pointer',
                }} p={'lg'} bg={'fngray'} >How can I program Hello World in python?</Box>
                <Box className={classes.newbox} onClick={() => props.handleSend('Give me a recommended pizza recipe')} style={{ cursor: 'pointer' }} p={'lg'} bg={'fngray'} >Give me a recommended pizza recipe</Box>
                <Box className={classes.newbox} onClick={() => props.handleSend('Create a description for my vlog')} style={{ cursor: 'pointer' }} p={'lg'} bg={'fngray'} >Create a description for my vlog</Box>
                <Box className={classes.newbox} onClick={() => props.handleSend('Help me sound like a professional when it comes to writing')} style={{ cursor: 'pointer' }} p={'lg'} bg={'fngray'}>Help me sound like a professional when it comes to writing</Box>
            </SimpleGrid>
        </Box>

    );
};

export default EmptyChat;