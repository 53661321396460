import { supabase } from '../supabase';

export class UserService {
    private static instance: UserService;
    private user: any | null = null;
    private fetchingUser: Promise<any> | null = null;

    private constructor() { }

    // Get the singleton instance of the service
    public static getInstance(): UserService {
        if (!UserService.instance) {
            UserService.instance = new UserService();
        }
        return UserService.instance;
    }

    // Fetch the user if not already fetched
    public getUser(): Promise<any> {
        if (this.user) {
            // Return cached user if already fetched
            return Promise.resolve(this.user);
        }

        if (this.fetchingUser) {
            // Return the existing fetch request if in progress
            return this.fetchingUser;
        }

        // Fetch the user from Supabase
        this.fetchingUser = supabase.auth.getUser()
            .then(({ data: { user }, error }) => {
                if (error) {
                    console.error('Error fetching user:', error);
                    return null;
                }
                this.user = user; // Cache the user
                return user;
            })
            .catch((err) => {
                console.error('Unexpected error:', err);
                return null;
            })
            .finally(() => {
                this.fetchingUser = null; // Clear fetching flag
            });

        return this.fetchingUser;
    }
}