import React, { useEffect } from 'react';
import '@mantine/core/styles.css';
import { Card, Grid, MantineProvider, Overlay, rem } from '@mantine/core';
import { useState } from 'react';
import { Router } from './Router';
import { theme } from './theme';
import { createClient } from '@supabase/supabase-js'
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import classes from './app.module.css';
import logo from './assets/fxloginicon.png';



export interface SupabaseAuthSession {
  access_token: string;
  refresh_token?: string;
  user: {
    id: string;
    aud: string;
    role?: string;
    email?: string;
    phone?: string;
    created_at: string;
    confirmed_at?: string;
    last_sign_in_at?: string;
  };
  expires_in?: number;
  expires_at?: number;
  token_type?: string;
}

const supabase = createClient('https://culshohgngkqzgcalywa.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImN1bHNob2hnbmdrcXpnY2FseXdhIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjM1ODE1NjAsImV4cCI6MjAzOTE1NzU2MH0.kVE25kwBqBHTafmWZ7T0Sa-bmsX0dJQ35VI_Gpm8eZY')



export default function App() {
  const [session, setSession] = useState<SupabaseAuthSession | null>(null);


  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })

    return () => subscription.unsubscribe()
  }, [])

  if (!session) {
    return <MantineProvider theme={theme}>

      <Grid justify="center" align="center">
        <Grid.Col span={4} style={{ minHeight: rem(80) }}>
          <Card radius="md" className={classes.card}>

            <div className={classes.content}>
              <div className={classes.logoContainer}>
                <div>
                  <img
                    src={logo}
                    alt="Logo"
                    className={classes.logo}
                  />
                  <p className={classes.text}>Log In</p>
                </div>
              </div>

              <Auth supabaseClient={supabase} providers={['google']} appearance={{
                theme: ThemeSupa,
                variables: {
                  default: {
                    colors: {
                      brand: '#242526',
                      brandAccent: '#242526',
                      defaultButtonBackgroundHover: '#242526',
                      defaultButtonBackground: '#F5F7FB',
                      inputBackground: '#F5F7FB',
                    },
                  },
                },
              }} />
            </div>
          </Card>
        </Grid.Col>
      </Grid>
    </MantineProvider >

  }

  return (
    <MantineProvider theme={theme}>
      <Router />
    </MantineProvider>
  );
}
