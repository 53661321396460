import React, { useState, useRef, useEffect } from 'react';
import { Button, Box, Text, ScrollArea, Skeleton, Paper } from '@mantine/core';
import classes from './ChatContent.module.css';
import Markdown from 'react-markdown'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import FunctionChatResponse from './FunctionChatResponse';
import ArrowDownIcon from '../Icons/ArrowDownIcon';

interface ChatProps {
    messages: ChatMessage[];
    loading: boolean;
}


const ChatContent: React.FC<ChatProps> = ({ messages, loading }) => {
    const [showButton, setShowButton] = useState<boolean>(false);
    const scrollAreaRef = useRef<HTMLDivElement>(null);
    const [scrollPosition, onScrollPositionChange] = useState({ x: 0, y: 0 });

    useEffect(() => {
        scrollToBottom();
    }, [messages]);


    const handleScroll = () => {
        if (scrollAreaRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollAreaRef.current;
            const isNearBottom = scrollTop + clientHeight >= scrollHeight * 0.85;
            setShowButton(!isNearBottom);
        }
    };

    const scrollToBottom = () => {
        if (scrollAreaRef.current) {
            scrollAreaRef.current.scrollTo({ top: scrollAreaRef.current.scrollHeight, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        handleScroll(); // Initial check
    }, [scrollPosition]);

    return (
        <div className={classes.scrollContainer}>
            <ScrollArea style={{ height: '100%' }} scrollbarSize={4} viewportRef={scrollAreaRef} onScrollPositionChange={onScrollPositionChange}>
                <Box p="md">
                    <Text>
                        <div>
                            {messages.map((message, index) => (
                                <div key={message.id}>
                                    {message.message.role === 'user' ?
                                        <Box style={{ textAlign: 'right' }}><Box mb={38} bg={'fnchatblue'} w={"60%"} style={{
                                            borderTopLeftRadius: '20px',
                                            borderTopRightRadius: '20px',
                                            borderBottomLeftRadius: '20px',
                                            borderBottomRightRadius: '0px',
                                            display: 'inline-block',
                                        }} p='xs'>
                                            <Text c={'white'} ta={"right"}>{message.message.content}</Text> </Box></Box>
                                        :
                                        <>
                                            <FunctionChatResponse message={message.message.content} />
                                        </>
                                    }
                                </div>
                            ))}
                        </div>
                        {loading ? <></> : <></>}


                    </Text>
                </Box>
            </ScrollArea>
            {showButton && (
                <div className={classes.buttonContainer}>
                    <Button variant="solid" bg={'fnchatblue'} onClick={scrollToBottom} leftSection={<ArrowDownIcon />}>
                        Scroll to Bottom
                    </Button>
                </div>
            )}
        </div>
    );
};

export default ChatContent;
